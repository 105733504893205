import React, { FC } from "react";

import 'styles/Spinner.scss';

const Spinner: FC = () => {
    return (
        <div className={'app_spinner'}>
            <div className="app_spinner--container"/>
        </div>
    );
}

export default Spinner;
