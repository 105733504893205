import React, { useState, createContext, ReactNode } from "react";

interface Modal {
    component: React.FC;
    props: object;
}

interface ModalContextProps {
    modalStack: Modal[];
    pushModal: (component: React.FC, props?: object) => void;
    popModal: () => void;
    closeAllModals: () => void;
}

export const ModalContext = createContext<ModalContextProps>({
    modalStack: [],
    pushModal: () => {},
    popModal: () => {},
    closeAllModals: () => {}
});

interface ModalProviderProps {
    children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
    const [modalStack, setModalStack] = useState<Modal[]>([]);

    const pushModal = (component: React.FC<any>, props: object = {}) => {
        setModalStack([...modalStack, { component, props }]);
    };

    const popModal = () => {
        const poppedModalStack = modalStack.slice(0, -1);
        setModalStack(poppedModalStack);
    };

    const state: ModalContextProps = {
        modalStack,
        pushModal,
        popModal,
        closeAllModals: () => setModalStack([])
    };

    return (
        <ModalContext.Provider value={state}>{children}</ModalContext.Provider>
    );
};

export const ModalConsumer = ModalContext.Consumer;
