import axios from 'axios';
// import createMock from "./mock";
const instance = axios.create();

if(process.env.NODE_ENV === 'development') {
    // createMock(instance);
    instance.defaults.baseURL = process.env.REACT_APP_API_URL;
}else{
    instance.defaults.baseURL = window.base_url || '';
}

export default instance;
