import {createSlice} from '@reduxjs/toolkit';
import {StepperStateInterface} from "types/stepper";

const initialState: StepperStateInterface = {
    step: 1
};

const stepperSlice = createSlice({
    name: 'stepper',
    initialState,
    reducers: {
        nextStep(state: StepperStateInterface) {
            state.step = state.step + 1;
        },
        prevStep(state: StepperStateInterface) {
            state.step = state.step - 1;
        },
        resetStepper(state: StepperStateInterface) {
            state.step = 1;
        }
    }
});

export const {nextStep, prevStep, resetStepper} = stepperSlice.actions;

export const getStep = (state: { stepper: StepperStateInterface }) => state.stepper.step;

export default stepperSlice.reducer;
