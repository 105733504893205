import React, {FC, Suspense} from 'react';
import Spinner from "./Spinner";

interface LoadComponentProps {
    RenderView: FC;
}

const LazyComponent: FC<LoadComponentProps> = ({RenderView}) => {
    return (
        <Suspense fallback={<Spinner/>}>
            <RenderView/>
        </Suspense>
    );
}

export default LazyComponent;
