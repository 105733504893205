import React, {FC, MouseEventHandler} from 'react';
import Logo from 'assets/BezEnd.png';
import Button from "components/Button";
import 'styles/BezEnd.scss';
import {getVoucherData} from "store/voucherSlice";
import {useSelector} from "react-redux";

interface BezEndProps {
    onClick: () => MouseEventHandler<HTMLButtonElement> | undefined;
}

const BezEnd: FC<BezEndProps> = ({onClick}) => {
    const {lifeTimePrice} = useSelector(getVoucherData);

    return (
        <div className={'app_bez-end'}>
            <div className={'app_bez-end--title'}>Hej, może Cię zainteresować!</div>
            <div className={'app_bez-end--wrapper'}>
                <div className={'app_bez-end--content'}>
                    <img src={Logo}
                         className={'app_bez-end--content_logo'} alt={'Voucher'}/>
                    <div className={'app_bez-end--content_text'}>
                        <div>
                            <p className={'solid'}>Jeśli dokupisz “Opcję BEZ ENDU” za <span
                                className={'app_bez-end--bg-text'}>{lifeTimePrice} PLN</span>
                                to kwota zapłacona za voucher nigdy nie przepadnie!</p>
                            <div className={'app_bez-end--normal-text'}>Po upływie ważności, voucher zmieni się w bezterminową kartę podarunkową i będzie można wykorzystać ją na zakupy w Prezentmarzeń.</div>
                        </div>
                    </div>
                </div>
                <div className={'app_voucher-changer--button'}>
                    <Button className={'button button--yellow'} onClick={onClick}>Dokup opcję BEZ ENDU</Button>
                </div>
            </div>
        </div>
    );
}

export default BezEnd;
