import React, {FC, ReactNode, useEffect} from "react";
import {useSelector} from "react-redux";
import {getStep} from "store/stepperSlice";
import {ModalRoot} from "components/Modal";

interface ComponentPropsInterface {
    id: number;
    component: ReactNode;
}

interface StepperPropInterface {
    components: ComponentPropsInterface[];
}

const Stepper: FC<StepperPropInterface> = ({components}) => {
    const step = useSelector(getStep);
    const currentComponent = components.find(c => c.id === step);

    useEffect(()=>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [step]);

    return (
        <>
            {currentComponent ? (
                <div key={currentComponent.id} className="stepper">
                    {currentComponent.component}
                    <ModalRoot/>
                </div>
            ) : (
                <div>Wybrany komponent nie istnieje.</div>
            )}
        </>
    );
}

export default Stepper;
