import React from 'react';
import {RouterProvider} from "react-router-dom";
import {Provider} from 'react-redux';

import 'styles/App.scss';
import router from "./router";
import store from "./store";
import {LayoutWrapper} from "./layout";

const App = () => {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <LayoutWrapper>
                    <RouterProvider router={router}/>
                </LayoutWrapper>
            </Provider>
        </React.StrictMode>
    );
}

export default App;
