import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import ReCaptcha from "./ReCaptcha";
import Button from "./Button";

import {AppDispatch} from "store";
import {getFormData, sendEmail} from "store/formSlice";
import {getVoucherData} from "store/voucherSlice";
import Alert from "components/Alert";
import {voucherStatusEnum} from "../types/voucher";

const EmailResend = () => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const voucher = useSelector(getVoucherData);
    const {voucherStatus} = voucher || {};
    const {error, message} = useSelector(getFormData);

    useEffect(() => {
        window.showRecaptcha();
        window.resetRecaptcha();
        return () => {
            window.resetRecaptcha();
        }
    }, []);

    const handleResend = async () => {
        try {
            setIsSubmitting(true);
            const recaptcha = window.grecaptcha.getResponse();
            const token = voucher.token;
            const hash = voucher.hash;
            return await dispatch(sendEmail({hash, token, recaptcha}));
        } catch (e) {
            console.error(e);
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <>
            {voucherStatus === voucherStatusEnum.IN_RESERVATION &&
                <div className="app_reservation--title">Voucher w trakcie rezerwacji</div>}
            <p className="app_reservation--subheader">Wysłaliśmy Ci już maila z danymi instruktora bądź firmy, gdzie
                rezerwuje i realizuje się voucher. Aby otrzymać je ponownie kliknij przycisk "Wyślij".</p>

            <div className={'app_voucher-changer--button'}>
                <ReCaptcha/>
            </div>
            <div className={'app_voucher-changer--button'}>
                <Button disabled={isSubmitting} className="button--main" onClick={handleResend}>Wyślij</Button>
            </div>
            {error &&
                <Alert message={message ?? 'Wystąpił błąd podczas wysyłania wiadomości, spróbuj ponownie później.'}
                       type={'danger'}/>}
        </>
    );
}

export default EmailResend;
