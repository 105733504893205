import React, { FC } from 'react';
import "styles/ImageCard.scss";

interface ImageCardProps {
    image: string;
    title: string;
}

const ImageCard: FC<ImageCardProps> = ({ image, title }) => {
    const isProd = window.location.hostname === 'prezentmarzen.com';

    return (
        <div className='image-card'>
            <h4 className={'image-description'}>{title}</h4>
            <picture>
                {isProd &&<source srcSet={`/cdn-cgi/image/format=avif/${image}`} type="image/avif"/>}
                {isProd && <source srcSet={`/cdn-cgi/image/format=webp/${image}`} type="image/webp"/>}
                <img className={'voucher-image'} src={image + '?1716789030'} alt={title} />
            </picture>
        </div>
    );
}

export default ImageCard;
