import React, { FC, ReactNode } from 'react';
import "styles/Content.scss"

interface ContentProps {
    title: string;
    children: ReactNode;
    space?: boolean;
}

const Content: FC<ContentProps> = ({ title, children, space }) => {
    return (
        <div className={space ? 'app_content--extra-space' : 'app_content'}>
            <h3 className={'app_content--title'}>{title}</h3>
            <div className="app_content--text">{children}</div>
        </div>
    );
}

export default Content;
