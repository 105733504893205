import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    addVoivodeship,
    getData,
    getSelectedCitiesIds,
    getSelectedVoivodeshipsIds,
    removeVoivodeship
} from "store/locationSlice";

import {AppDispatch} from "store";
import {ModalWrapper} from "components/Modal";
import LocationButton from "components/LocationFilter/LocationButton";
import {ModalConsumer} from "context/ModalContext";
import Spinner from "components/Spinner";
import {fetchBox, resetPage} from "store/boxSlice";
import {getHash} from "store/formSlice";

const VoivodeshipPicker = () => {
    const dispatch = useDispatch<AppDispatch>();
    const data = useSelector(getData);
    const hash = useSelector(getHash);
    const selectedCitiesIds = useSelector(getSelectedCitiesIds);
    const selectedVoivodeshipIds = useSelector(getSelectedVoivodeshipsIds);

    const isSelected = (id: number): boolean => {
        return selectedVoivodeshipIds.includes(id);
    }

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement|HTMLDivElement>, popModal: () => void) => {
        e.preventDefault();
        dispatch(resetPage());
        popModal();
        dispatch(fetchBox({hash, city: selectedCitiesIds, voivodeship: selectedVoivodeshipIds}));
    };

    return (
        <ModalWrapper>
            <ModalConsumer>
                {({popModal}) => (<div className={'app_modal-close--button'} onClick={(e) => handleButtonClick(e, popModal)}/>)}
            </ModalConsumer>
            <div>
                <h1 className={''}>Wybierz województwo</h1>
                <p>(możesz zaznaczyć więcej niż jedno)</p>
                <div className={`wrapper-locations ${data.length === 0 ? 'spacer' : ''}`}>
                    {data.map((voivodeship) => (
                        <LocationButton
                            key={voivodeship.id}
                            name={voivodeship.voivodeship}
                            id={voivodeship.id}
                            selected={isSelected(voivodeship.id)}
                            add={addVoivodeship}
                            remove={removeVoivodeship}
                        />
                    ))}
                </div>

                <div className={'app_voucher-changer--button'}>
                    <ModalConsumer>
                        {({popModal}) => (<button type="button" className={'app_box--select-button'} onClick={(e) => handleButtonClick(e, popModal)}>Wybieram</button>)}
                    </ModalConsumer>
                </div>
                {data.length === 0 && <Spinner/>}
            </div>
        </ModalWrapper>
    )
}

export default VoivodeshipPicker;
