import {useSelector} from "react-redux";
import {VoucherCheckerForm} from "components/Forms";
import VoucherGuidebook from "components/VoucherGuidebook";
import Spinner from "components/Spinner";
import React from "react";

interface RootState {
    voucher: {
        loading: boolean;
    };
}

const VoucherChecker = () => {
    const loading = useSelector((state: RootState) => state.voucher.loading);

    return (
        <div className="root-container">
            <div className="form-wrapper">
                <VoucherCheckerForm/>
            </div>
            <VoucherGuidebook/>
            {loading && <Spinner/>}
        </div>
    );
}

export default VoucherChecker;
