import React, { forwardRef, ChangeEvent, FocusEvent } from 'react';

import 'styles/TextInput.scss';

interface TextInputProps {
    label?: string;
    inputValue?: string | number | undefined;
    name?: string | undefined
    placeholder?: string;
    error?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
    className?: string;
}

const TextInput = forwardRef(function TextInput(props: TextInputProps, ref: any) {
    const { label, name, placeholder, error, onChange, onFocus, className } = props;

    return (
        <div className={`app_input ${className}`}>
            <div className="input-label-container">
                <label htmlFor={name ?? ''} className="input-label">{label}</label>
            </div>
            <input
                id={name ?? ''}
                ref={ref}
                type="text"
                name={name ?? ''}
                onChange={onChange ?? (() => {}) }
                placeholder={placeholder ?? ''}
                className={error ? "input-error input-icon" : ""}
                onFocus={onFocus ?? (() => {}) }
            />
            {error && (
                <div className="error-message">
                    <svg
                        className={'error-icon'}
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width={16}
                        height={16}
                        viewBox="0 0 13.065 11.28"
                    >
                        <defs>
                            <clipPath id="clip-path">
                                <rect
                                    id="Rectangle_2216"
                                    data-name="Rectangle 2216"
                                    width={13.065}
                                    height={11.28}
                                    fill="#fff"
                                />
                            </clipPath>
                        </defs>
                        <g id="alert_icon" transform="translate(0 0)">
                            <g
                                id="Group_9546"
                                data-name="Group 9546"
                                transform="translate(0 0)"
                                clipPath="url(#clip-path)"
                            >
                                <path
                                    id="Path_9553"
                                    data-name="Path 9553"
                                    d="M0,11.28,6.533,0l6.532,11.28H0M6.533,9.545a.511.511,0,1,0-.371-.15.506.506,0,0,0,.371.15M6.049,7.859h.967V4.636H6.049Z"
                                    transform="translate(0 0)"
                                    fill="#fff"
                                />
                            </g>
                        </g>
                    </svg>
                    {error}
                </div>
            )}
        </div>
    );
});

export default TextInput;
