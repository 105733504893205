import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {ModalConsumer} from "context/ModalContext";
import {AppDispatch} from "store";

import {clearStore, fetchLocations, getSelectedCitiesIds, getSelectedVoivodeshipsIds} from "store/locationSlice";

import Button from "components/Button";
import CitiesPicker from "components/LocationFilter/CitiesPicker";
import VoivodeshipPicker from "components/LocationFilter/VoivodeshipPicker";
import RenderSelectedLocations from "components/LocationFilter/RenderSelectedLocations";

import 'styles/Locations.scss';
import {getHash} from "store/formSlice";
import {fetchBox} from "store/boxSlice";

const LocationFilter = () => {
    const hash = useSelector(getHash);
    const selectedVoivodeshipsIds = useSelector(getSelectedVoivodeshipsIds);
    const selectedCitiesIds = useSelector(getSelectedCitiesIds);
    const dispatch = useDispatch<AppDispatch>();
    const handleClickButtonVoivodeship = async (e: React.MouseEvent<HTMLButtonElement>, pushModal: any) => {
        e.preventDefault();
        pushModal(VoivodeshipPicker);
        await dispatch(fetchLocations({hash}));
    }

    const handleClickButtonCity = async (e: React.MouseEvent<HTMLButtonElement>, pushModal: any) => {
        e.preventDefault();
        pushModal(CitiesPicker);
    }

    const handleClickButtonClear = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(clearStore());
        dispatch(fetchBox({hash, city: [], voivodeship: []}));
    }

    return (
        <div>
            <ModalConsumer>
                {({pushModal}: any) => (
                    <>
                        <Button key={'voivodeshipPicker'}
                                className={'button--small button--purple button--margin-top'}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClickButtonVoivodeship(e, pushModal)}>
                            Wybierz lokalizację
                        </Button>
                        {selectedVoivodeshipsIds.length > 0 &&
                            <Button key={'citysPicker'}
                                    className={'button--small button--outlined button--margin-top button--margin-left'}
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClickButtonCity(e, pushModal)}>
                                Wybierz miasto
                            </Button>
                        }
                        {(selectedVoivodeshipsIds.length > 0 || selectedCitiesIds.length > 0) &&
                            <Button key={'filter'}
                                    className={'button--small button--gray button--margin-top button--margin-left'}
                                    onClick={handleClickButtonClear}>Wyczyść filtr</Button>
                        }
                    </>
                )}
            </ModalConsumer>
            <div>
                <RenderSelectedLocations/>
            </div>
        </div>
    )
}

export default LocationFilter;
