import React, {FC} from "react";
import {useModal} from "hooks/useModal";

const ModalRoot: FC = () => {
    const { modalStack, popModal } = useModal();

    if (modalStack.length === 0) return null;

    return (
        <>
            {modalStack.map(({ component: Component, props }, index) => (
                // @ts-ignore
                <Component key={index} {...props} handleClose={() => popModal()} />
            ))}
        </>
    );
};

export default ModalRoot;
