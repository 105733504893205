import React, {forwardRef, ReactNode} from 'react';
import 'styles/RadioButton.scss';

interface RadioButtonProps {
    name: string;
    value: string;
    label: string | ReactNode;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
}

const RadioButton = forwardRef(function RadioButton(props: RadioButtonProps, ref: any) {
    const {name, value, label, onChange, checked} = props;

    return (
        <div className={'app_radio'}>
            <input
                type="radio"
                ref={ref}
                id={name + value}
                name={name}
                value={value}
                onChange={onChange}
                checked={checked}
            />
            <label htmlFor={name + value}>{label}</label>
        </div>
    );
});

export default RadioButton;
