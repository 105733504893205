import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "api/index";
import {ApiResponse} from "types/apiResponse";
import {BoxInterface, BoxPayloadInterface, BoxState} from "types/box";
import {RootStateInterface} from "types/store";

const initialState: BoxState = {
    loading: false,
    page: 1,
    limit: 12,
    totalPages: 1,
    data: [],
    error: null,
    message: null,
};

const boxSlice = createSlice({
    name: 'box',
    initialState,
    reducers: {
        setTotalPages(state, action: { payload: number | undefined }) {
            if(action.payload === undefined) {
                return;
            }

            state.totalPages = action.payload;
        },
        nextPage(state) {
            state.page++;
        },
        resetPage(state) {
            state.page = 1;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(start, (state: BoxState, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(success, (state: BoxState, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(failed, (state: BoxState, action) => {
                state.loading = false;
                state.error = true;
                state.message = action.payload;
            });
    }
});

export const {nextPage, resetPage, setTotalPages} = boxSlice.actions;

const start = createAction('box/fetchDataStart');
const success = createAction<Array<BoxInterface>>('box/fetchDataSuccess');
const failed = createAction<string>('box/fetchDataFailure');

export const fetchBox = createAsyncThunk(
    'box/fetchData',
    async (payload: BoxPayloadInterface, {dispatch, getState}) => {
        try {
            dispatch(start());
            const store = getState() as RootStateInterface;
            const {hash, city, voivodeship} = payload;

            const {data} = await axios.post<ApiResponse<BoxInterface[]>>(`/boxItems`, {
                hash,
                page: store.box.page,
                limit: store.box.limit,
                filter: {
                    city: city ?? [],
                    voivodeship: voivodeship ?? []
                }
            });

            if (data.code !== 1000) {
                return dispatch(failed(data.message));
            }

            dispatch(setTotalPages(data.totalPages));
            if (store.box.page > 1) {
                dispatch(success([...store.box.data, ...data.data]));
            } else {
                dispatch(success(data.data));
            }

            return data;
        } catch (error) {
            dispatch(failed("Coś poszło nie tak"));
        }
    }
);

export const getBox = (state: RootStateInterface) => state.box.data;
export const getBoxLoading = (state: RootStateInterface) => state.box.loading;
export const hasNextPage = (state: RootStateInterface) => state.box.page < state.box.totalPages;

export default boxSlice.reducer;
