import {VoucherInterface, voucherStatusEnum, voucherTypeEnum} from "../types/voucher";

const isGreaterThanZero = (v: string) => {
    let value: string | number = v;
    if (typeof value === 'string') {
        if (value.includes('PLN')) {
            value = parseFloat(value.replace('PLN', '').trim().replace(',', '.'));

            return !isNaN(value) && value > 0;
        } else if (value.includes('%')) {
            value = parseFloat(value.replace('%', '').trim());

            return !isNaN(value) && value > 0;
        }
    }

    return false;
}

const isActive = (voucher: VoucherInterface | undefined): boolean => {
    if(voucher === undefined) return false;

    const {expireIn, isLifeTimeVoucher, voucherType, discount, price} = voucher;

    if (!expireIn) {
        return false;
    }

    const expiryDate = new Date(voucher.expireIn);
    const now = new Date();

    if(voucherType === voucherTypeEnum.GIFTCARD && voucher.voucherStatus === voucherStatusEnum.ACTIVE){
        if (isLifeTimeVoucher) {
            return isGreaterThanZero(discount);
        }

        return (expiryDate > now) && isGreaterThanZero(discount);
    }

    if (voucherType === voucherTypeEnum.CART_RULE && voucher.voucherStatus === voucherStatusEnum.ACTIVE) {
        if (isLifeTimeVoucher) {
            return isGreaterThanZero(discount);
        }

        return (expiryDate > now) && isGreaterThanZero(discount);
    }

    if (voucher.voucherStatus === voucherStatusEnum.ACTIVE || voucher.voucherStatus === voucherStatusEnum.IN_RESERVATION) {
        return expiryDate > now;
    }

    return false;
}

export default isActive;
