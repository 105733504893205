import React, {FC} from 'react';
import {VoucherInterface, voucherStatusEnum} from "types/voucher";
import isActive from "utils/isActive";
import moment from "moment";

import 'styles/VoucherInfo.scss';
import {getDefaultImage, getImageUrl} from "utils/image";

const Default: FC<VoucherInterface> = (voucher) => {
    const {image, productName, hash, expireIn, voucherStatus} = voucher;

    const isUsed = voucherStatus === voucherStatusEnum.USED || voucherStatus === voucherStatusEnum.REALIZED || voucherStatus === voucherStatusEnum.TERMINATED || voucherStatus === voucherStatusEnum.SETTLED;
    const isBlocked = voucherStatus === voucherStatusEnum.BLOCKED;
    const showVoucherExpiredDate = !isUsed && !isBlocked && (voucherStatus !== voucherStatusEnum.IN_ORDER);

    return (
        <div
            className={`app_voucher app_voucher${isActive(voucher) ? '--active' : '--inactive_default'} app_voucher--margin-top`}>
            {image && <img className={'app_voucher__image'} src={getImageUrl(image)} alt={productName} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = getDefaultImage();
            }} />}
            <div className='app_voucher__description'>
                <p className="app_voucher__title">{productName}</p>
                <p className="app_voucher__number app_voucher__status app_mt-20">Numer: <span>{hash.toUpperCase()}</span></p>
                {(showVoucherExpiredDate && voucherStatus!== voucherStatusEnum.EXPIRED) && <p className="app_voucher__expiry">Ważny do: <span>{moment(expireIn).format("DD.MM.YYYY")}</span></p>}
                {voucherStatus === voucherStatusEnum.INACTIVE && <p className="app_voucher__message--small">Voucher nieaktywny.</p>}
                {voucherStatus === voucherStatusEnum.EXPIRED && <p className="app_voucher__message--small">Voucher przeterminowany.</p>}
                {isUsed && <p className="app_voucher__message--small">Voucher został wykorzystany.</p>}
                {voucherStatus === voucherStatusEnum.BLOCKED && <p className="app_voucher__message--small">Voucher został zablokowany.</p>}
                {voucherStatus === voucherStatusEnum.IN_ORDER && <p className="app_voucher__message--small">Błąd. Skontaktuj się z nami.  <br/>Tel: {window.reservation_phone}</p>}
                {voucherStatus === voucherStatusEnum.IN_CART && <p className="app_voucher__message--small">Voucher w zamówieniu.</p>}
                {/*{voucherStatus === voucherStatusEnum.IN_RESERVATION && <p className="app_voucher__message--small">Voucher jest w trakcie rezerwacji.</p>}*/}
            </div>
        </div>
    )
}

export default Default;
