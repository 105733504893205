import React, {FC, useEffect} from 'react';
import "styles/Box.scss";
import InfiniteScroll from "react-infinite-scroll-component";

import Product from "./Product";
import redirect from 'utils/redirect';
import {useDispatch, useSelector} from "react-redux";
import {fetchBox, getBox, getBoxLoading, hasNextPage, nextPage} from "store/boxSlice";
import {getVoucherData} from "store/voucherSlice";
import moment from "moment";
import LocationFilter from "components/LocationFilter";
import Spinner from "components/Spinner";
import {getSelectedCitiesIds, getSelectedVoivodeshipsIds} from "store/locationSlice";
import {OptionsEnum} from "types/voucher";
import VoucherChanger from "../VoucherChanger";
import BezEnd from "../BezEnd";

const Box: FC = () => {
    const dispatch = useDispatch();
    const products = useSelector(getBox);
    const voucher = useSelector(getVoucherData);
    const loading = useSelector(getBoxLoading);
    const _hasNextPage = useSelector(hasNextPage);
    const selectedCitiesIds = useSelector(getSelectedCitiesIds);
    const selectedVoivodeshipIds = useSelector(getSelectedVoivodeshipsIds);
    const hash = voucher.hash;

    useEffect(()=>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    },[])

    const fetchMoreData = () => {
        if (_hasNextPage) {
            dispatch(nextPage());
            // @ts-ignore
            dispatch(fetchBox({hash, city: selectedCitiesIds, voivodeship: selectedVoivodeshipIds}));
        }
    }

    return (
        <div className={'app_box'}>
            <div className={'app_box--header'}>{voucher.productName}</div>
            <div className={'app_box--content'}>
                <div className={'pack_content col-xs-12 col-sm-12 col-md-8'}>
                    <h1 className="app_box--header">Wybierz z boxu atrakcję, którą chcesz zrealizować</h1>
                    <LocationFilter/>
                    <InfiniteScroll
                        dataLength={products.length}
                        next={fetchMoreData}
                        hasMore={true}
                        loader={<></>}>
                        <ul className={'product_list grid'}>
                            {products.map((product, index) => (<Product key={index} product={product} index={index}/>))}
                        </ul>
                    </InfiniteScroll>
                </div>
                <div className={'col-xs-12 col-sm-12 col-md-4'}>
                    <div className={'app_box--voucher-info'}>Informacja o twoim voucherze</div>
                    <div className={'app_box--sticky-content'}>
                        <div className={`app_voucher--active app_voucher--margin-top`}>
                            <div className='app_voucher__description'>
                                <p className="app_voucher__title">{voucher.productName}</p>
                                <p className="app_voucher__number app_voucher__status">Kod: <span>{hash}</span></p>
                                <p className="app_voucher__expiry">Ważny do: <span>{moment(voucher.expireIn).format("DD.MM.YYYY")}</span></p>
                            </div>
                        </div>
                        {voucher.options.includes(OptionsEnum.LIFETIME) ? <BezEnd onClick={() => redirect(`/m/lm_voucherlifetime/extend/?number=${voucher.hash}`)}/> : null}
                        {voucher.options.includes(OptionsEnum.CHANGE) ? <VoucherChanger key={'change'} onClick={() => redirect(`/wymiana/?code=${voucher.hash}`)}/> : null}
                    </div>
                </div>
            </div>
            {loading && <Spinner/>}
        </div>
    );
}

export default Box;
