import React from 'react';
import {VoucherInterface, voucherTypeEnum} from "types/voucher";
import GiftCard from "./GiftCard";
import CartRule from "./CartRule";
import Default from "./Default";
import VoucherBox from "./VoucherBox";

interface VoucherInfoProps {
    voucher: VoucherInterface;
}

const VoucherInfo: React.FC<VoucherInfoProps> = ({voucher}) => {
    const {voucherType} = voucher;

    if (voucherType === voucherTypeEnum.GIFTCARD) {
        return <GiftCard {...voucher} />;
    }

    if (voucherType === voucherTypeEnum.CART_RULE) {
        return <CartRule {...voucher} />;
    }

    if(voucherType === voucherTypeEnum.BOX) {
        return <VoucherBox {...voucher} />;
    }

    return <Default {...voucher} />;
}

export default VoucherInfo;

