import {createAction, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "api";
import {
    SupplierInterface,
    VoucherInterface,
    VoucherPayloadInterface,
    VoucherState,
    voucherStatusEnum,
    voucherTypeEnum
} from "types/voucher";
import {ApiResponse} from "types/apiResponse";
import {RootStateInterface} from "types/store";
import {setEmail, setNewsletterConsent, setVoucherNumber} from "./formSlice";
import {nextStep} from "./stepperSlice";

const initialState: VoucherState = {
    loading: false,
    data: {
        hash: '',
        price: '',
        discount: '',
        voucherType: voucherTypeEnum.EMPTY,
        voucherStatus: voucherStatusEnum.EMPTY,
        options: [],
        productName: '',
        image: '',
        lifeTimePrice: '',
        expireIn: '',
        isLifeTimeVoucher: false,
        token: ''
    },
    showOptions: false,
    error: null,
    message: null,
    redirectUrl: null
};

const voucherSlice = createSlice({
    name: 'voucher',
    initialState: initialState,
    reducers: {
        setRedirect: (state: VoucherState, action: PayloadAction<string>) => {
            state.redirectUrl = action.payload;
        },
        setShowOptions: (state: VoucherState, action: PayloadAction<boolean>) => {
            state.showOptions = action.payload;
        },
        resetStore: (state: VoucherState) => {
            return initialState;
        },
        setAvailableSuppliers: (state: VoucherState, action: PayloadAction<SupplierInterface[]>) => {
            state.data.availableSuppliers = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(start, (state: VoucherState) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(success, (state: VoucherState, action) => {
                state.loading = false;
                state.data = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(failed, (state: VoucherState, action) => {
                state.loading = false;
                state.error = true;
                state.message = action.payload;
            });
    },
});

export const {setRedirect, setShowOptions, resetStore, setAvailableSuppliers} = voucherSlice.actions;

const start = createAction('voucher/fetchDataStart');
const success = createAction<VoucherInterface>('voucher/fetchDataSuccess');
const failed = createAction<string>('voucher/fetchDataFailure');

export const checkVoucher = createAsyncThunk(
    'voucher/fetchData',
    async (payload: VoucherPayloadInterface, {dispatch}) => {
        try {
            dispatch(start());
            dispatch(resetStore());
            const {hash, isNewsletterAccepted, isPrivacyAccepted, isTermsAccepted, email, recaptcha} = payload;

            const {data} = await axios.post<ApiResponse<VoucherInterface>>(`/CheckVoucher`, {
                hash, isNewsletterAccepted, isPrivacyAccepted, isTermsAccepted, email, recaptcha
            });

            if (data.code !== 1000) {
                return dispatch(failed(data.message));
            }

            const {voucherType, voucherStatus, expireIn} = data.data;

            dispatch(success(data.data));
            dispatch(setVoucherNumber(hash));
            dispatch(setNewsletterConsent(isNewsletterAccepted));
            dispatch(setEmail(email));

            if(voucherType === voucherTypeEnum.TICKET && voucherStatus === voucherStatusEnum.USED){
                return dispatch(setRedirect(`/rezerwacja-bilety/?code=${hash}&from=1`));
            }

            if(voucherType === voucherTypeEnum.GIFTCARD){
                return dispatch(nextStep());
            }

            if (voucherType === voucherTypeEnum.CART_RULE) {
                return dispatch(setShowOptions(true));
            }

            if (voucherType === voucherTypeEnum.EMPTY) {
                return dispatch(failed(data.message ?? "Nie znaleziono vouchera"));
            }

            // if (voucherStatus === voucherStatusEnum.ACTIVE || voucherStatus === voucherStatusEnum.IN_RESERVATION) {
                return dispatch(nextStep());
            // }
        } catch (error) {
            dispatch(failed("Coś poszło nie tak"));
        }
    }
);

export const getVoucherData = (state: RootStateInterface) => state.voucher.data;

export default voucherSlice.reducer;
