export const getImageUrl = (url: string) => {
    const mediaPath = 'https://cdn-dev.prezentmarzen.com';

    if (url.startsWith('http')) {
        return url;
    }

    return `${mediaPath}${url}`;
}

export const getDefaultImage = () => {
    return 'https://cdn.prezentmarzen.com/img/p/6/3/0/7/4/63074-home_default.jpg';
}
