import { configureStore, combineReducers } from '@reduxjs/toolkit';
import voucherSlice from './voucherSlice';
import formSlice from './formSlice';
import boxSlice from './boxSlice';
import stepperSlice from './stepperSlice';
import locationSlice from "./locationSlice";

const rootReducer = combineReducers({
    voucher: voucherSlice,
    form: formSlice,
    box: boxSlice,
    stepper: stepperSlice,
    location: locationSlice
});

const store = configureStore({
    reducer: rootReducer
});

export default store;
export type AppDispatch = typeof store.dispatch;
