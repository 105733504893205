import React, {FC} from 'react';
import moment from "moment";
import {useSelector} from "react-redux";
import 'styles/VoucherInfo.scss';

import {ModalConsumer} from "context/ModalContext";
import {getSelectedProduct, isProductSelected} from "store/formSlice";
import {VoucherInterface, voucherStatusEnum} from "types/voucher";
import {isBox} from "utils/voucherType";
import Box from "components/Box";
import {getStep} from "store/stepperSlice";
import isActive from "utils/isActive";
import {getDefaultImage, getImageUrl} from "utils/image";

const VoucherBox: FC<VoucherInterface> = (voucher) => {
    const {
        image,
        productName,
        hash,
        expireIn,
        voucherType,
        isLifeTimeVoucher,
        voucherStatus
    } = voucher || {} as VoucherInterface

    const _isProductSelected = useSelector(isProductSelected);
    const selectedProduct = useSelector(getSelectedProduct);
    const step = useSelector(getStep);

    const isUsed = voucherStatus === voucherStatusEnum.USED || voucherStatus === voucherStatusEnum.REALIZED || voucherStatus === voucherStatusEnum.TERMINATED || voucherStatus === voucherStatusEnum.SETTLED;

    return (
        <div>
            {step !== 3 && <div className={`app_voucher app_voucher${isActive(voucher) ? '--active' : '--inactive_default'} ${_isProductSelected ? 'app_voucher--box' : ''} app_voucher--margin-top`}>
                {image && <img className={'app_voucher__image'} src={getImageUrl(image)} alt={productName} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src= getDefaultImage();
                }}/>}
                <div className='app_voucher__description'>
                    <p className="app_voucher__title">{productName}</p>
                    <p className="app_voucher__number app_voucher__status app_mt-20">Numer: <span>{hash.toUpperCase()}</span></p>
                    {(isActive(voucher) && voucherStatus === voucherStatusEnum.ACTIVE) && <p className="app_voucher__expiry">Ważny do: <span>{moment(expireIn).format("DD.MM.YYYY")}</span></p>}
                    {voucherStatus === voucherStatusEnum.INACTIVE && <p className="app_voucher__message--small">Voucher nieaktywny.</p>}
                    {voucherStatus === voucherStatusEnum.EXPIRED && <p className="app_voucher__message--small">Voucher przeterminowany.</p>}
                    {isUsed && <p className="app_voucher__message--small">Voucher został wykorzystany.</p>}
                    {voucherStatus === voucherStatusEnum.BLOCKED && <p className="app_voucher__message--small">Voucher został zablokowany.</p>}
                    {voucherStatus === voucherStatusEnum.IN_ORDER && <p className="app_voucher__message--small">Błąd. Skontaktuj się z nami.  <br/>Tel: {window.reservation_phone}</p>}
                    {voucherStatus === voucherStatusEnum.IN_CART && <p className="app_voucher__message--small">Voucher w zamówieniu.</p>}
                    {/*{voucherStatus === voucherStatusEnum.IN_RESERVATION && <p className="app_voucher__message--small">Voucher jest w trakcie rezerwacji.</p>}*/}
                </div>
            </div>}

            {isBox(voucherType) && _isProductSelected &&
                <div className='app_voucher app_voucher--active'>
                    {image &&
                        <img className='app_voucher__image' src={getImageUrl(selectedProduct.product_image)} alt={productName} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src= getDefaultImage();
                        }}/>}
                    <div className='app_voucher__description'>
                        {step === 3 && <p className="app_voucher__title">{selectedProduct.product_name}</p>}
                        {step === 3 && <p className="app_voucher__number app_voucher__status">Numer: <span>{hash.toUpperCase()}</span></p>}
                        {step === 3 && <p className="app_voucher__expiry">Ważny do: <span>{moment(expireIn).format("DD.MM.YYYY")}</span></p>}
                        {step !== 3 && <div className="app_voucher__title--regular">Twój wybór to:</div>}
                        {step !== 3 && <div className="app_voucher__title--regular app_mt-20">{selectedProduct.product_name}</div>}
                        {step !== 3 && <div className="app_voucher__button">
                            <ModalConsumer>
                                {({pushModal}) => (<span onClick={() => pushModal(Box)}>ZMIEŃ</span>)}
                            </ModalConsumer>
                        </div>}
                    </div>
                </div>}

            {isBox(voucherType) && voucherStatus === voucherStatusEnum.VOUCHER_UNMARKED && step === 3 && <div
                className={`app_voucher app_voucher${isActive(voucher) ? '--active' : '--inactive_default'} ${_isProductSelected ? 'app_voucher--box' : ''} app_voucher--margin-top`}>
                {image && <img className={'app_voucher__image'} src={getImageUrl(image)} alt={productName} onError={({currentTarget}) => {currentTarget.onerror = null;}}/>}
                <div className='app_voucher__description'>
                    <p className="app_voucher__title">{productName}</p>
                    <p className="app_voucher__number app_voucher__status app_mt-20">Numer: <span>{hash.toUpperCase()}</span>
                    </p>
                </div>
            </div>}
        </div>
    );
}

export default VoucherBox;
