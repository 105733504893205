import React, {FC} from 'react';
import {RadioButton} from "components/Input";
import {SupplierInterface} from "types/voucher";

interface LinkProps {
    url: string;
    text: string;
}

const Link: FC<LinkProps> = ({url, text}) => {

    if (!url) {
        return (
            <>
                {text}
            </>
        );
    }

    return (
        <>
            {text} - <span className='link'><a href={url}>Sprawdź tu dostępne terminy</a></span>
        </>
    );
}

interface SupplierPickerProps {
    register: any;
    suppliers: SupplierInterface[] | undefined;
}

const SupplierPicker: FC<SupplierPickerProps> = ({register, suppliers}) => {

    if (!suppliers || suppliers.length === 0) return null;

    if (suppliers.length === 1) return (
        <div className={'hidden'}>
            <RadioButton value={suppliers[0].id_supplier}
                         label={<Link text={suppliers[0].name} url={suppliers[0].calendar}/>} {...register("supplier")}
                         checked/>
        </div>
    )

    return (
        <>
            <div className="app_reservation--title ">Dostępni dostawcy</div>
            {suppliers.map((supplier, index) => (
                <RadioButton key={index} value={supplier.id_supplier}
                             label={<Link text={supplier.name} url={supplier.calendar}/>} {...register("supplier")} />
            ))}
        </>
    );
};

export default SupplierPicker;
