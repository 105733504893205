import React, { FC } from 'react';

import "styles/Gallery.scss"
import ImageCard from "components/VoucherGuidebook/ImageCard/ImageCard";

interface GalleryProps {
    items: {
        image: string;
        title: string;
    }[];
}

const Gallery: FC<GalleryProps> = ({ items }) => {
    return (
        <div className='app_gallery'>
            {items.map((item, index) => (
                <ImageCard key={index} {...item} />
            ))}
        </div>
    );
}

Gallery.defaultProps = {
    items: [{
        image: "",
        title: "Voucher"
    }]
};

// Gallery.propTypes = {
//     items: PropTypes.arrayOf(PropTypes.shape({
//         image: PropTypes.string.isRequired,
//         title: PropTypes.string.isRequired
//     })).isRequired
// };

export default Gallery;
