import {SupplierInterface} from "./supplier";

export enum summaryTypeEnum {
    RESERVATION = 'reservation',
    EMAIL = 'email'
}

export interface FormStateInterface {
    loading: boolean
    error: boolean,
    message: string | null,
    voucherNumber: string;
    email: string;
    phoneNumber: string;
    product_id: number | null;
    product_attribute: number | null;
    product_image: string | null;
    product_name: string | null;
    summaryType: summaryTypeEnum | null;
    summary: SupplierInterface
    isNewsletterAccepted: boolean;
}

export interface FormPayloadInterface {
    hash: string;
    email: string;
    phone: string;
    supplier: number;
    emailMarketingConsent: number;
    product_id: number;
    attribute_id: number;
    recaptcha: string;
    product_attribute: number;
}
