import React, {FC, useEffect, useState} from 'react';

interface ReCaptchaProps {
}

const ReCaptcha: FC<ReCaptchaProps> = () => {
    const [show, setShow] = useState<boolean>(false);
    window.showRecaptcha = () => setShow(true);
    window.resetRecaptcha = () => {
        if (show) {
            try {
                window.grecaptcha.reset();
            } catch (e) {
                console.log("Błąd recaptchy")
            }
        }
    };

    useEffect(() => {
        if (!show) return;

        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js';
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [show]);

    const recaptchaKey = (): string => {
        let key: string = '';

        if (window.captcha_public_key) {
            key = window.atob(window.captcha_public_key);
        }

        if (!key) {
            return process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';
        }

        return key;
    };

    return (
        <div id="recaptcha" className="g-recaptcha" data-sitekey={recaptchaKey()} data-callback=""></div>
    );
};

export default ReCaptcha;
