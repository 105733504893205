export enum voucherTypeEnum {
    BOX = 'box',
    GIFTCARD = 'giftcart',
    VOUCHER = 'voucher',
    TICKET = 'ticket',
    CART_RULE = 'cart_rule',
    EMPTY = '',
}

export enum voucherStatusEnum {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    IN_RESERVATION = 'in_reservation',
    IN_ORDER = 'in_order',
    USED = 'used',
    IN_CART = 'in_cart',
    TERMINATED = 'terminated',
    BLOCKED = 'blocked',
    EXPIRED = 'expired',
    REALIZED = 'realized',
    SETTLED = 'settled',
    EMPTY = '',
    VOUCHER_UNMARKED = 'unmarked',
}

export enum OptionsEnum {
    LIFETIME = "lifetime",
    INFO = "info",
    SENDEMAIL = "resendEmail",
    RESERVATION = "reservation",
    CHANGE = "change",
}

export interface VoucherInterface {
    hash: string;
    price: string;
    discount: string;
    voucherType: voucherTypeEnum;
    voucherStatus: voucherStatusEnum;
    options: OptionsEnum[];
    productName: string;
    image: string;
    lifeTimePrice: string;
    expireIn: string;
    isLifeTimeVoucher: boolean;
    token: string;
    availableSuppliers?: SupplierInterface[];
}

export interface SupplierInterface {
    id_supplier: number;
    name: string;
    calendar: string;
}

export interface VoucherState {
    loading: boolean;
    showOptions: boolean;
    data: VoucherInterface;
    error: boolean | null;
    message: string | null;
    redirectUrl: string | null;
}

export interface VoucherPayloadInterface {
    hash: string;
    email: string;
    isNewsletterAccepted: boolean;
    isPrivacyAccepted: boolean;
    isTermsAccepted: boolean;
    recaptcha: string;
}
