import React, {useState} from 'react';
import 'styles/VoucherReservationForm.scss';
import type {AppDispatch} from "store";

import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {getFormData, isProductSelected, sendForm} from "store/formSlice";
import {ModalConsumer} from "context/ModalContext";

import {Checkbox, TextInput} from "components/Input";
import Button from "components/Button";
import SupplierPicker from "./SupplierPicker";
import errorMessage from "hooks/useErrorMessage";
import ReCaptcha from "components/ReCaptcha";
import Box from "components/Box";
import {fetchBox} from "store/boxSlice";
import {getVoucherData} from "store/voucherSlice";
import Alert from "../../Alert";

const VoucherReservationForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const formData = useSelector(getFormData);
    const voucher = useSelector(getVoucherData);
    const {availableSuppliers, voucherType, hash} = voucher;
    const error = useSelector((state: any) => state.form.error);
    const message = useSelector((state: any) => state.form.message);

    const _isProductSelected = useSelector(isProductSelected);
    const isBox = voucherType === 'box';


    const onSubmit = async (data: any) => {
        try {
            setIsSubmitting(true);
            const recaptcha = window.grecaptcha.getResponse();
            await dispatch(sendForm({...data, ...formData, recaptcha}));
            window.resetRecaptcha();
        } catch (e) {
            console.error(e);
        }finally {
            setIsSubmitting(false);
        }
    };

    const handleClickButton = async (e: React.MouseEvent<HTMLButtonElement>, pushModal: any) => {
        e.preventDefault();
        await dispatch(fetchBox({hash}));
        pushModal(Box);
    };

    return (
        <form className={`app_reservation`} onSubmit={handleSubmit(onSubmit)}>
            {(isBox && _isProductSelected) || !isBox ? (
                <>
                    <div className="app_reservation--title">Zarezerwuj Prezent</div>
                    <p className="app_reservation--subheader">
                        <b>Aby zrealizować ten prezent, podaj swoje dane kontaktowe.</b> Wyślemy Ci maila z danymi instruktora
                        bądź firmy, gdzie bezpośrednio dokonasz rezerwacji i zrealizujesz prezent.
                    </p>
                </>
            ) : null}

            <SupplierPicker register={register} suppliers={availableSuppliers}/>
            {isBox && !_isProductSelected && (
                <div className={'app_voucher-changer--button'}>
                    <ModalConsumer>
                        {({pushModal}: any) => (
                            <Button
                                className="button--main button--margin-top"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClickButton(e, pushModal)}
                            >
                                Wybierz prezent z boxu
                            </Button>
                        )}
                    </ModalConsumer>
                </div>
            )}

            {isBox && !_isProductSelected ? null : (
                <TextInput
                    label={'Telefon'}
                    placeholder={'Wpisz numer telefonu'}
                    error={errorMessage(errors, 'phone')}
                    {...register('phone', {
                        required: true,
                        pattern: {
                            value: /^\d+$/,
                            message: 'Podany numer telefonu jest niepoprawny',
                        },
                        minLength: 9,
                    })}
                />
            )}

            {isBox && !_isProductSelected ? null : (
                <TextInput
                    label={'Twój adres e-mail'}
                    placeholder={'Adres e-mail'}
                    onFocus={() => window.showRecaptcha()}
                    error={errorMessage(errors, 'email')}
                    {...register('email', {
                        required: 'required',
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Podaj poprawny adres email',
                        },
                    })}
                />
            )}

            {isBox && !_isProductSelected ? null : (
                <Checkbox
                    onChange={(e) => setIsTermsAccepted(e.target.checked)}
                    checked={isTermsAccepted}
                    label={'*Oświadczam, że zapoznałem/am się z treścią <a href="/regulamin/" target="_blank" rel="noopener">Regulaminu</a> i akceptuję jego postanowienia w celu dokonania rezerwacji vouchera.'}
                    required
                />
            )}

            <div className={`app_reservation--recaptcha`}>
                <ReCaptcha/>
            </div>
            <div className={`app_voucher-changer--button`}>
                {isBox && !_isProductSelected ? null : (
                    <Button disabled={isSubmitting} className="button--main button--margin-top">Wyślij</Button>
                )}
            </div>
            {error && <Alert message={message ?? 'Wystąpił błąd podczas rezerwacji vouchera, spróbuj ponownie później.'} type={'danger'}/>}
        </form>
    );
};

export default VoucherReservationForm;
