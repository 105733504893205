const useErrorMessage = (errors: any, name: string): string => {

    if (errors[name] === undefined) {
        return '';
    }

    if (errors[name].type === 'required') {
        return 'To pole jest wymagane';
    }

    if (errors[name].type === 'minLength') {
        return 'Podana wartość jest za krótka';
    }

    if (errors[name].type === 'pattern') {
        return errors[name].message;
    }

    return '';
};

export default useErrorMessage;
