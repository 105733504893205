import {createMemoryRouter, MemoryRouterProps} from "react-router-dom";
import Root from "views/root";
import React from "react";


interface Route {
    path: string;
    element: JSX.Element;
    loader: () => string;
}

const routes: Route[] = [
    {
        path: "/",
        element: <Root/>,
        loader: () => 'FAKE_EVENT',
    },
    // {
    //     path: "/voucher",
    //     element: <LoadComponent RenderView={VoucherReservation} />,
    //     loader: () => 'FAKE_EVENT',
    // }
];

const router = createMemoryRouter(routes, {
    initialEntries: ["/"],
    initialIndex: 1,
} as MemoryRouterProps);

export default router;
