import {createAction, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FormPayloadInterface, FormStateInterface, summaryTypeEnum} from "types/form";
import axios from "api";
import {ApiResponse} from "types/apiResponse";
import {SupplierInterface} from "types/supplier";
import {nextStep} from "./stepperSlice";
import {RootStateInterface} from "../types/store";
import {setRedirect} from "./voucherSlice";

const initialState: FormStateInterface = {
    loading: false,
    error: false,
    message: null,
    voucherNumber: '',
    email: '',
    phoneNumber: '',
    product_id: null,
    product_attribute: null,
    product_image: '',
    product_name: '',
    summaryType: null,
    isNewsletterAccepted: false,
    summary: {
        supplierName: '',
        voucherExpireIn: '',
        supplierExtraInfo: '',
        email: '',
        fullName: '',
        address: '',
        description: '',
        calendar: '',
        contacts: [],
        redirectLink: ''
    }
};

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        setSummaryType(state, {payload}: PayloadAction<summaryTypeEnum>) {
            state.summaryType = payload;
        },
        setProductId(state, {payload}: PayloadAction<number>) {
            state.product_id = payload;
        },
        setProductAttribute(state, {payload}: PayloadAction<number>) {
            state.product_attribute = payload;
        },
        setProductImage(state, {payload}: PayloadAction<string>) {
            state.product_image = payload;
        },
        setProductName(state, {payload}: PayloadAction<string>) {
            state.product_name = payload;
        },
        setVoucherNumber(state, action: PayloadAction<string>) {
            state.voucherNumber = action.payload;
        },
        setEmail(state, action: PayloadAction<string>) {
            state.email = action.payload;
        },
        setPhoneNumber(state, action: PayloadAction<string>) {
            state.phoneNumber = action.payload;
        },
        setNewsletterConsent(state, action: PayloadAction<boolean>) {
          state.isNewsletterAccepted = action.payload;
        },
        resetForm(state) {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(start, (state: FormStateInterface, action) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(success, (state: FormStateInterface, action) => {
                state.loading = false;
                state.summary = action.payload;
            })
            .addCase(failed, (state: FormStateInterface, action) => {
                state.loading = false;
                state.error = true;
                state.message = action.payload;
            })
            .addCase(sendEmailStart, (state: FormStateInterface, action) => {
                state.loading = true;
                state.error = false;
                state.message = null;
            })
            .addCase(sendEmailSuccess, (state: FormStateInterface, action) => {
                state.loading = false;
                state.summary = action.payload;
            })
            .addCase(sendEmailFailed, (state: FormStateInterface, action) => {
                state.loading = false;
                state.error = true;
                state.message = action.payload;
            })
    }
});

const start = createAction('form/fetchDataStart');
const success = createAction<SupplierInterface>('form/fetchDataSuccess');
const failed = createAction<string>('form/fetchDataFailure');

export const sendForm = createAsyncThunk(
    'form/sendForm',
    async (payload: FormPayloadInterface, {dispatch, getState}) => {
        try {
            dispatch(start());
            const {email, phone, supplier, product_id, product_attribute, recaptcha} = payload;
            const store = getState() as RootStateInterface;
            const hash = store.form.voucherNumber;
            const email_marketing_consent = store.form.isNewsletterAccepted ? 1 : 0;
            const attribute_id = product_attribute;

            const {data} = await axios.post<ApiResponse<SupplierInterface>>(`/voucherReservation`, {
                hash, email, phone, supplier, email_marketing_consent, product_id, attribute_id, recaptcha
            });

            if (data.code !== 1000) {
                return dispatch(failed(data.message));
            }

            if (data.data.redirectLink) {
                return dispatch(setRedirect(data.data.redirectLink));
            }

            dispatch(success(data.data));
            dispatch(nextStep());

            return data;
        } catch (error) {
            dispatch(failed("Coś poszło nie tak"));
        } finally {
            dispatch(setSummaryType(summaryTypeEnum.RESERVATION));
        }
    }
);

interface SendEmailPayload {
    hash: string;
    token: string;
    recaptcha: string;
}

const sendEmailStart = createAction('form/sendEmailStart');
const sendEmailSuccess = createAction<SupplierInterface>('form/sendEmailSuccess');
const sendEmailFailed = createAction<string>('form/sendEmailFailed');

export const sendEmail = (payload: SendEmailPayload) =>
    (dispatch: any) => {
        dispatch(sendEmailStart())

        const {hash, token, recaptcha} = payload;
        const api = `/sendEmail`;

        return new Promise((resolve, reject) =>
            axios.post<ApiResponse<SupplierInterface>>(api, {
                token, recaptcha, hash
            }).then(({data}) => {
                if (data.code === 1000) {
                    dispatch(sendEmailSuccess(data.data));
                    dispatch(nextStep());
                    resolve(data.message)
                } else {
                    dispatch(sendEmailFailed(data.message))
                    resolve(data.message)
                }
            }).catch((error) => {
                dispatch(sendEmailFailed(error.message))
                resolve(error)
            }).finally(() => {
                dispatch(setSummaryType(summaryTypeEnum.EMAIL));
            })
        )
    }

export const {
    setVoucherNumber,
    setEmail,
    setPhoneNumber,
    resetForm,
    setProductId,
    setProductAttribute,
    setSummaryType,
    setProductImage,
    setProductName,
    setNewsletterConsent
} = formSlice.actions;

export const getFormData = (state: { form: FormStateInterface }) => state.form;
export const isProductSelected = (state: { form: FormStateInterface }) => state.form.product_id !== null && state.form.product_attribute !== null;
export const getSelectedProduct = (state: { form: FormStateInterface }) => {
    return {
        product_id: state.form.product_id,
        product_attribute: state.form.product_attribute,
        product_name: state.form.product_name,
        product_image: state.form.product_image ?? '',
    }
}
export const getHash = (state: { form: FormStateInterface }) => state.form.voucherNumber;
export const getSummary = (state: { form: FormStateInterface }) => state.form.summary;

export default formSlice.reducer;
