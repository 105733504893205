import React, { FC, MouseEventHandler } from 'react';
import Button from 'components/Button';

import 'styles/VoucherChanger.scss';

interface VoucherChangerProps {
    onClick: MouseEventHandler<HTMLButtonElement> | undefined | any;
}

const VoucherChanger: FC<VoucherChangerProps> = ({ onClick }) => {
    return (
        <div className={'app_voucher-changer'}>
            <div className={'app_voucher-changer--title'}>Nietrafiony prezent? Nic straconego!</div>
            <div className={'app_voucher-changer--content'}>
                Jeżeli otrzymałeś prezent, ale okazał się on nie do końca trafiony to pamiętaj, że w Prezentmarzeń
                możesz go wymienić na inny. Skorzystaj z poniższej opcji i wybierz inną atrakcję z naszej oferty!
            </div>
            <div className={'app_voucher-changer--button'}>
                <Button className={'button button--outlined button--margin-top'} onClick={onClick}>
                    Wymień na inny prezent
                </Button>
            </div>
        </div>
    );
}

export default VoucherChanger;
