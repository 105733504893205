import React, { ReactNode } from 'react';

interface LayoutWrapperProps {
    children: ReactNode;
}

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children }) => {
    const isDevelopment: boolean = process.env.REACT_APP_DEVELOPMENT === 'true';

    if (isDevelopment) {
        return (
            <div className={'container'}>
                {children}
            </div>
        );
    }

    return (<>{children}</>);
};

export default LayoutWrapper;
