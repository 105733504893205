import React from "react";
import useRedirect from "hooks/useRedirect";
import Stepper from "components/Stepper";
import VoucherChecker from "./Reservation/VoucherChecker";
import LazyComponent from "components/LazyComponent";
import {ModalProvider} from "context/ModalContext";
import Spinner from "../components/Spinner";

const VoucherReservation = React.lazy(() => import('./Reservation/VoucherReservation'));
const ReservationSummary = React.lazy(() => import('./Reservation/ReservationSummary'));

const Root = () => {
    const isRedirecting = useRedirect();

    const components = [
        {
            id: 1,
            component: <VoucherChecker/>
        },
        {
            id: 2,
            component: <LazyComponent RenderView={VoucherReservation}/>
        },
        {
            id: 3,
            component: <LazyComponent RenderView={ReservationSummary}/>
        }
    ]

    return (
        <ModalProvider>
            <Stepper components={components}/>
            {isRedirecting && <Spinner/>}
        </ModalProvider>
    );
}

export default Root;
