import React, {FC, ReactNode} from "react";

import 'styles/Modal.scss';

interface LayoutWrapperProps {
    children: ReactNode;
}

const ModalWrapper: FC<LayoutWrapperProps> = ({children}) => {
    return (
        <div className={'app_modal'}>
            <div className={'app_modal-dialog'}>
                {children}
            </div>
        </div>
    )
}

export default ModalWrapper;
