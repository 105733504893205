import React, {ButtonHTMLAttributes, FC, ReactNode} from 'react';
import 'styles/Button.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    children: ReactNode;
    onClick?: any;
}

const Button: FC<ButtonProps> = ({children, onClick, className, ...props}) => {
    return (
        <button className={`button ${className}`} onClick={onClick ?? (() => {
        })} {...props}>
            {children}
        </button>
    );
};

export default Button;
