import React, {FC} from 'react';
import {VoucherInterface, voucherStatusEnum} from "types/voucher";
import isActive from "utils/isActive";
import moment from "moment";

import 'styles/VoucherInfo.scss';
import {getDefaultImage, getImageUrl} from "utils/image";

const GiftCard: FC<VoucherInterface> = (voucher) => {
    const {image, productName, hash, expireIn, discount, isLifeTimeVoucher, voucherStatus} = voucher;

    return (
        <div className={`app_voucher app_voucher${isActive(voucher) ? '--active' : '--inactive_default'} app_voucher--margin-top`}>
            <img className={'app_voucher__image'} src={getDefaultImage()} alt={productName} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = getDefaultImage();
            }}/>
            <div className='app_voucher__description'>
                <p className="app_voucher__title">Karta Podarunkowa</p>
                <p className="app_voucher__number app_voucher__status app_mt-20">Numer: <span>{hash.toUpperCase()}</span></p>
                {isActive(voucher) && <p className="app_voucher__amount">Dostępne środki: <span>{discount}</span></p>}
                {!isActive(voucher) && <p className={`app_voucher__subtitle ${isActive(voucher) ? '' : 'app_voucher__subtitle--inactive'}`}>
                    Aktywny: <span>{isActive(voucher) ? "TAK" : "NIE"}</span>
                </p>}

                {(!isLifeTimeVoucher && isActive(voucher)) && <p className="app_voucher__expiry">Ważny do: <span>{moment(expireIn).format("DD.MM.YYYY")}</span></p>}
                {(isLifeTimeVoucher && isActive(voucher)) && <p className="app_voucher__expiry">Ważny do: <span>BEZTERMINOWO</span></p>}

                {(voucherStatus === voucherStatusEnum.ACTIVE && !isLifeTimeVoucher && isActive(voucher)) && <p className={'app_voucher__message--small app_mt-20'}>Użyj tej karty jako płatności podczas zakupów.</p>}
                {voucherStatus === voucherStatusEnum.INACTIVE && <p className="app_voucher__message--small">Karta została wykorzystana lub zablokowana.</p>}
                {voucherStatus === voucherStatusEnum.IN_ORDER && <p className="app_voucher__message--small">Błąd. Skontaktuj się z nami. <br/>Tel: {window.reservation_phone}</p>}
            </div>
        </div>
    )
}

export default GiftCard;
