import React, {FC} from 'react';
import {VoucherInterface} from "types/voucher";
import isActive from "utils/isActive";
import moment from "moment";

import 'styles/VoucherInfo.scss';
import {getDefaultImage, getImageUrl} from "utils/image";


const GiftCart: FC<VoucherInterface> = (voucher) => {
    const {productName, hash, expireIn, discount, isLifeTimeVoucher, image} = voucher;

    return (
        <div
            className={`app_voucher app_voucher${isActive(voucher) ? '--active' : '--inactive'} app_voucher--margin-top`}>
            <img className={'app_voucher__image'} src={getImageUrl(image)} alt={productName} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = getDefaultImage();
            }} />
            <div className='app_voucher__description'>
                <p className="app_voucher__number app_voucher__status">Numer: <span>{hash.toUpperCase()}</span></p>
                <p className={`app_voucher__subtitle ${isActive(voucher) ? '' : 'app_voucher__subtitle--inactive app_mt-20'}`}>
                    Aktywny: <span>{isActive(voucher) ? "TAK" : "NIE"}</span>
                </p>
                {isLifeTimeVoucher && <p className="app_voucher__expiry">Ważny do: <span>BEZTERMINOWO</span></p>}
                {!isLifeTimeVoucher && <p className="app_voucher__expiry">Ważny do: <span>{moment(expireIn).format("DD.MM.YYYY")}</span></p>}
                <p className="app_voucher__amount">Rabat: <span>{discount}</span></p>
                {isActive(voucher) && <p className={'app_voucher__message--small'}>Dokonaj zakupu za pomocą tego kodu, a następnie zarezerwuj kupiony prezent.</p>}
            </div>
        </div>
    )
}

export default GiftCart;
