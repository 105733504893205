import React, {FC} from 'react';
import {ModalConsumer} from "context/ModalContext";
import {useDispatch} from "react-redux";
import {setProductAttribute, setProductId, setProductImage, setProductName} from "store/formSlice";

import {BoxInterface} from "types/box";
import ProductDetails from "./ProductDetails";
import {setAvailableSuppliers} from "store/voucherSlice";
import {getDefaultImage, getImageUrl} from "utils/image";

interface ProductProps {
    product: BoxInterface;
    index: number;
}

const Product: FC<ProductProps> = ({product, index}) => {
    const dispatch = useDispatch();
    const {id, attribute, productName, localization, rating, ratingTotal, image, suppliers} = product;
    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>, popModal: () => void) => {
        e.preventDefault();
        dispatch(setAvailableSuppliers(suppliers));
        dispatch(setProductId(id));
        dispatch(setProductAttribute(attribute));
        dispatch(setProductName(productName));
        dispatch(setProductImage(image));
        window.scrollTo({top: 0, behavior: 'smooth'});
        popModal();
    };

    const handleProductClick = (e: React.MouseEvent<HTMLAnchorElement>, pushModal: (component: React.FC<any>, props: BoxInterface) => void) => {
        e.preventDefault();
        pushModal(ProductDetails, product);
    }

    return (
        <ModalConsumer>
            {({popModal, pushModal}) => (
                <li className={'col-xs-6 col-sm-6 col-md-3 block_product'} key={index}>
                    <div className={"product__box"}>
                        <a className={"product__image-link"} onClick={(e) => handleProductClick(e, pushModal)} title={productName}>
                          <span className={"product__wrapper d-block"}>
                            <span className={"product__left-block d-block"}>
                              <span className={"product__container d-block"}>
                                <img className={"product__image lazy"}
                                     src={getImageUrl(image)}
                                     alt={productName}
                                     title={productName}
                                     width="262" height="341"
                                     onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = getDefaultImage();
                                    }}
                                />
                                <span className={"product__details d-block"}>
                                  <span className={"product__reviews d-block"}>
                                    <span className={"product__rating-content d-block"}>
                                      <i className={"icon-rating product__rating"}/>
                                      <i className={"icon-rating-filled product__rating product__rating--filled"}
                                         style={{width: `${(rating * 100) / 5}%`}}/>
                                    </span>
                                    <span className={"product__rating-total"}>({ratingTotal})</span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <span className={"product__right-block d-block"}>
                              <span className={"product__info d-block"}>
                                <span className={"product__content d-block"}>
                                  <span className={"product__name-wrapper d-block"}>
                                    <span className={"product__name product__name--click"}
                                          dangerouslySetInnerHTML={{__html: productName}}/>
                                  </span>
                                    {localization && (<span className={"product__localization d-block"}
                                                            dangerouslySetInnerHTML={{__html: localization}}/>)}
                                </span>
                              </span>
                            </span>
                          </span>
                        </a>
                            <button type="button" className={'app_box--select-button'}
                                    onClick={(e) => handleButtonClick(e, popModal)}>Wybieram
                            </button>
                    </div>
                </li>
            )}
        </ModalConsumer>
    );
};

export default Product;
