import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";

const useRedirect = (): boolean => {
    let navigate = useNavigate();
    const redirect: string | null = useSelector((state: any) => state.voucher.redirectUrl);
    const [isRedirecting, setRedirecting] = useState(false);

    const setTimer = () => {
        setRedirecting(true);
        setTimeout(() => {
            setRedirecting(false);
        }, 5000);
    }

    useEffect(() => {
        if (redirect) {
            setTimer();
            window.location.href = redirect;
        }
    }, [redirect, navigate])

    return isRedirecting;
}

export default useRedirect;
