import React from "react";
import {useDispatch} from "react-redux";
import {AppDispatch} from "store";

interface LocationButtonProps {
    name: string,
    id: number,
    selected: boolean,
    add: (id: number) => any,
    remove: (id: number) => any
}

const LocationButton: React.FC<LocationButtonProps> = ({name, id, selected, add, remove}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isSelected, setSelected] = React.useState(selected ?? false);

    const handleClick = () => {
        dispatch(isSelected ? remove(id) : add(id));
        setSelected(prevState => !prevState);
    }

    return (
        <button className={`location__button${isSelected ? '--selected' : '--available'} `} onClick={handleClick}>
            <span>{name}</span>
            <i className="location__icon"></i>
        </button>
    )
}

export default LocationButton;
