import React, {FC} from 'react';
import 'styles/Alert.scss';

interface AlertProps {
    message: string;
    type: string;
}

const Alert: FC<AlertProps> = ({message, type}) => {
    return (
        <div className={'app_alert'}>
            <div className={`app_alert--${type}`} dangerouslySetInnerHTML={{__html: message}} />
        </div>
    );
}

export default Alert;
