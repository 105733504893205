import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import Button from './Button';
import BezEnd from './BezEnd';
import VoucherChanger from './VoucherChanger';
import {VoucherReservationForm} from './Forms';
import EmailResend from './EmailResend';
import redirect from 'utils/redirect';
import {getVoucherData} from "store/voucherSlice";
import {OptionsEnum, voucherStatusEnum} from "types/voucher";
import {isBox, isCartRule, isTicket, isVoucher} from "utils/voucherType";
import VoucherInfo from "./VoucherInfo";
import {getStep} from "../store/stepperSlice";


const Options: FC = () => {
    const voucher = useSelector(getVoucherData);
    const step = useSelector(getStep);
    const {hash, options, voucherType, voucherStatus} = voucher || {};
    const componentsToShow: JSX.Element[] = [];

    if (!options) return null;

    const excludedStatuses = [
        voucherStatusEnum.INACTIVE,
        voucherStatusEnum.REALIZED,
        voucherStatusEnum.SETTLED,
        voucherStatusEnum.BLOCKED,
        voucherStatusEnum.IN_ORDER,
        voucherStatusEnum.TERMINATED,
    ];

    if (isCartRule(voucherType)) {
        componentsToShow.push(<VoucherInfo voucher={voucher}/>);
    }

    if (excludedStatuses.includes(voucherStatus) && !isCartRule(voucherType)) {
        return (
            <>
                {/*<VoucherInfo voucher={voucher}/>*/}
                {options.includes(OptionsEnum.INFO) && <div className={'app_voucher-changer--button'}>
                    <Button key={'info'} className={'button--main'} onClick={() => redirect(`/`)}>
                        Wróć do sklepu
                    </Button>
                </div>}
                <div className={'app_voucher-changer--button'}>
                    <Button key={'info'} className={'button--main'} onClick={() => redirect(`/rezerwacja`)}>
                        Sprawdź inny voucher
                    </Button>
                </div>
            </>
        )
    }

    if (isTicket(voucherType)) {
        if (voucherStatus === voucherStatusEnum.ACTIVE) {
            componentsToShow.push(
                <div className={'app_voucher-changer--button'}>
                    <Button className={'button--main button--margin-top'}
                            onClick={() => redirect(`/rezerwacja-bilety/?code=${hash}&from=1`)}>
                        Wymień voucher na bilet
                    </Button>
                </div>
            );
        }

        if (voucherStatus !== voucherStatusEnum.ACTIVE && voucherStatus !== voucherStatusEnum.EXPIRED) {
            return componentsToShow.push(
                <div className={'app_voucher-changer--button'}>
                    <Button key={'info'} className={'button--main'} onClick={() => redirect(`/rezerwacja`)}>
                        Sprawdź inny voucher
                    </Button>
                </div>
            );
        }
    }

    if (voucherStatus === voucherStatusEnum.IN_RESERVATION || voucherStatus === voucherStatusEnum.VOUCHER_UNMARKED) {
        componentsToShow.push(<EmailResend key={'emailResend'}/>);
    }

    if (options.includes(OptionsEnum.RESERVATION) && (isBox(voucherType) || isVoucher(voucherType)) && voucherStatus === voucherStatusEnum.ACTIVE) {
        componentsToShow.push(<VoucherReservationForm key={'reservation'}/>);
    }

    if (options.includes(OptionsEnum.LIFETIME)) {
        componentsToShow.push(
            <BezEnd key={'extend'} onClick={() => redirect(`/m/lm_voucherlifetime/extend/?number=${hash}`)}/>
        );
    }

    if (options.includes(OptionsEnum.CHANGE)) {
        componentsToShow.push(<VoucherChanger key={'change'} onClick={() => redirect(`/wymiana/?code=${hash}`)}/>);
    }

    if (options.includes(OptionsEnum.INFO)) {
        componentsToShow.push(
            <div className={'app_voucher-changer--button'}>
                <Button key={'info'} className={'button--main button--margin-top'} onClick={() => redirect(`/`)}>
                    Wróć do sklepu
                </Button>
            </div>
        );
    }

    if (step > 1) {
        componentsToShow.push(
            <div className={'app_voucher-changer--button'}>
                <Button key={'info'} className={'button--main'} onClick={() => redirect(`/rezerwacja`)}>
                    Sprawdź inny voucher
                </Button>
            </div>
        );
    }

    return <>{componentsToShow}</>;
};

export default Options;
